<template>
  <div class="manage_layer_01">
    <div class="layer_top">
      <h2 class="layer_top_title">댓글</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_01">
      <form>
        <table>
          <colgroup>
            <col style="width: 120px" />
            <col style="width: 320px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">등록일시</td>
            <td class="box_style_02">
              {{ registerDate }}
            </td>
          </tr>
          <tr class="line_style" v-if="updateDate != ''">
            <td class="box_style_02 list_title_style">수정일시</td>
            <td class="box_style_02">
              {{ updateDate }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">내용</td>
            <td class="box_style_03">
              {{ content }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">회원아이디</td>
            <td class="box_style_02">
              {{ user.loginid }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">회원이름</td>
            <td class="box_style_02">
              {{ user.name }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      registerDate: "",
      updateDate: "",
      content: "",
      user: {
        loginid: "",
        name: "",
      },
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.commentFindone(this.id).then(
        (response) => {
          const { data } = response;
          this.registerDate = this.$d(new Date(data.registerDate), "long");
          try {
            data.updateDate = `${this.$d(
              new Date(data.updateDate).getTime(),
              "long"
            )}`;
          } catch {
            data.updateDate = "";
          }
          this.updateDate = data.updateDate;
          this.content = data.content;
          this.user.loginid = data.user.loginid;
          this.user.name = data.user.name;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
